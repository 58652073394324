import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "teamTitle" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teams, (team) => {
    return (_openBlock(), _createElementBlock("div", {
      key: team,
      class: "teamWrapper"
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(team), 1),
      _createVNode(_component_el_carousel, {
        interval: 2000,
        type: "card",
        height: "100px"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usrs?.filter((x) => x.name == team), (usr) => {
            return (_openBlock(), _createBlock(_component_el_carousel_item, { key: usr }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: _ctx.getPicture(usr.icon),
                  style: {"width":"100px"}
                }, null, 8, _hoisted_2)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 2
      }, 1024)
    ]))
  }), 128))
}