<template>
  <div
    v-if="blocked()"
    class="mg-t-15"
    style="
      min-height: 100vh;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 10px;
    "
  >
    <div class="m-txt-right">
      <el-button
        type="warning"
        v-if="!currentTeamMission?.solved_at"
        class="mg-t-3 mg-r-3 d-clickable"
        @click="inds = !inds"
        >HELP</el-button
      >
    </div>
    <div class="fs-18 fw-bold mg-b-18 pd-t-10">
      {{ currentTeamMission?.mission.title }}
    </div>

    <pre class="pd-g-15 fs-15 m-txt-left" style="white-space: pre-wrap">{{
      currentTeamMission?.mission.description
    }}</pre>

    <div v-if="!currentTeamMission?.solved_at">
      <Indices
        v-if="inds"
        :missionId="currentTeamMission?.mission_id"
        :teamId="currentTeamMission?.team_id"
      />

      <div class="gen-code-confirm" v-if="!inds">
        <input v-model="code" />
        <el-badge :value="currentTeamMission?.try" class="mg-t-9" type="info">
          <button @click="checkCode()" class="pd-t-5 pd-b-5 pd-l-10 pd-r-10">
            Confirmer
          </button>
        </el-badge>
      </div>
    </div>
    <div v-else>Bien joué, mission réussie</div>
  </div>
  <div v-else style="color: aliceblue; font-size: 20px">
    Reviens le : {{ displayDate() }}
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, defineProps, watch } from "vue";
import { supabase } from "@/lib/supabaseClient";
import { mission, team_mission } from "@/models/models";
import { useRoute, Router, useRouter } from "vue-router";
import router from "@/router/index";
import Indices from "@/components/Indices.vue";
import { ElNotification, ElMessageBox } from "element-plus";

interface Props {
  idm: string;
}

let queryString = window.location.search;
let urlParams = new URLSearchParams(queryString);
const teamId = ref<string | null>("");
const missionId = ref<string | null>("");
const route = useRoute();
//const router = useRouter();
const props = defineProps<Props>();
const code = ref<string>("");
const inds = ref<boolean>(false);
//let missionId: string | string[];
//let currentMission = ref<mission | null>();
let currentTeamMission = ref<team_mission>();

// reactive state

// lifecycle hooks
onMounted(() => {
  // Team ID in the URL

  const route = useRoute();
  teamId.value = route.params.id;
  missionId.value = route.params.idm;
  getMission(missionId.value);
});

/**
 * @summary: Watch all update of route.param.idm
 */
watch(
  () => route.params.idm,
  (newId: string, oldId: string) => {
    // Load mission when url changes
    getMission(newId);
  }
);

function displayDate() {
  const disp: string = currentTeamMission.value?.blocked_at?.toString();
  return disp;
}
async function getMission(idmission: string | null) {
  await supabase
    .from("team_mission")
    .select(`*, mission(id, title, code, resolved, description)`)
    .match({
      team_id: teamId.value,
      mission_id: idmission,
    })
    .maybeSingle()
    .then((d: team_mission[]) => {
      currentTeamMission.value = d.data;
      //debugger;
    });
}

function blocked() {
  const blk: number = Date.parse(currentTeamMission.value?.blocked_at);
  const now: number = new Date().getTime();
  console.log(currentTeamMission.value?.blocked_at);
  //debugger;
  if (!currentTeamMission.value?.blocked_at) {
    return true;
  } else {
    return blk - now < 0 ? true : false;
  }
}

function tryingMessage(tryNumber: number) {
  switch (tryNumber) {
    case 0:
      return "Aie aie mauvais code, ça commence mal";
    case 1:
      return "De mieux en mieux";
    case 2:
      return "Tu le fais expres ou quoi ?";

    default:
      return "Je ne sais plus quoi dire... irrécupérable";
  }
}

async function checkCode() {
  // Code OK

  ElMessageBox.confirm(
    "T'es sûr de ton coup ?",
    "C'est ton dernier mot Jean-Pierre ? ",
    {
      confirmButtonText: "Biensûr, tu me prends pour qui ?",
      cancelButtonText: "Dézo, j'aurais pas du appuyer",
      type: "infos",
    }
  )
    .then(async () => {
      if (code.value === currentTeamMission.value.mission?.code) {
        await supabase
          .from("team_mission")
          .update({
            try: currentTeamMission.value.try + 1,
            solved_at: new Date().toISOString().toLocaleString(),
          })
          .eq("id", currentTeamMission.value.id)
          .then(async () => {
            if (currentTeamMission.value.team_mission_id) {
              // Get Next mission

              await supabase
                .from("team_mission")
                .select("*")
                .match({
                  team_id: teamId.value,
                  ord: currentTeamMission.value.ord + 1,
                })
                .maybeSingle()
                .then(async (d: team_mission) => {
                  //debugger;

                  // Update team_mission next
                  await supabase
                    .from("team_mission")
                    .update({
                      unlocked_at: new Date().toISOString().toLocaleString(),
                    })
                    .eq("id", d.data.id)
                    .then(() => {
                      console.log(d.data.mission_id);

                      code.value = "";
                      ElNotification({
                        title: "Bravo",
                        message: "Mission réussie, bien joué mon gaté",
                        position: "top-right",
                        type: "success",
                      });
                      router.push({
                        name: "mission",
                        params: { id: d.data.team_id, idm: d.data.mission_id },
                      });
                    });
                });
            } else {
              router.push({
                name: "team",
                params: { id: currentTeamMission.value?.team_id },
              });
            }
          });
      } else {
        // Code Not OK
        if (code.value.length === 0) {
          ElNotification({
            title: "...",
            message: "Fais au moins l'effort de mettre un code sérieux :(",
            position: "top-right",
            type: "warning",
          });
        } else {
          await supabase
            .from("team_mission")
            .update({ try: currentTeamMission.value.try + 1 })
            .eq("id", currentTeamMission.value.id)
            .then((d) => {
              ElNotification({
                title: "Aie aie aie",
                message: tryingMessage(currentTeamMission.value.try),
                position: "top-right",
                type: "error",
              });
              getMission(currentTeamMission.value.mission_id);
            });
        }
      }
    })
    .catch(() => {
      ElNotification({
        title: "Ohh",
        message:
          "Fais attention avant d'appuyer, tu crois que ça m'amuse d'afficher la notif ? ",
        position: "top-right",
        type: "info",
      });
    });
}

// functions that mutate state and trigger updates
</script>

<style lang="scss" scoped>
.gen-code-confirm {
  display: grid;
  justify-content: space-evenly;
  justify-items: center;
  font-size: 15px;
  & input {
    background-color: rgba(100, 100, 100, 0.6);
    /* opacity: 0.6; */
    color: #cacaca;
    padding: 5px;
    margin-bottom: 5px;
    border: none;
    color: white;
  }

  & button {
    border-radius: 10px;
    border: none;
    background-color: rgb(32, 72, 32);
    color: white;
  }
}

.help-title {
  display: flex;
  flex-direction: columns;
  align-items: flex-start;
}
.help-title .mission-help {
  //align-self: self-start;
}

.help-title .mission-title {
  align-self: center;
}
</style>
