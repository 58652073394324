<template>
  <div style="display: flex; justify-content: space-evenly; text-align: center">
    <div
      v-for="(m, index) in missions"
      :key="m.id"
      @click="!m.unlocked_at ? unlockedMission() : props.handler(m.mission_id)"
    >
      <div
        class="d-clickable mission-style"
        :class="[
          m.solved_at
            ? 'resolved'
            : m.unlocked_at !== null && m.solved_at === null
            ? 'running'
            : 'locked',
          {
            active: m.mission_id === route.params.idm,
          },
        ]"
      >
        {{ index + 1 }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, defineProps, watch } from "vue";
import { supabase } from "@/lib/supabaseClient";
import { team_mission } from "@/models/models";
import { useRoute } from "vue-router";
import { ElNotification } from "element-plus";
interface Props {
  teamId: string;
  handler: (x: string) => void;
}

const props = defineProps<Props>();

// reactive state
let missions = ref<team_mission[] | null>([]);
const route = useRoute();

watch(
  () => route.params.idm,
  () => {
    // Load mission when url changes
    getParams();
  }
);

// lifecycle hooks
onMounted(() => {
  getParams();
});

// functions that mutate state and trigger updates
async function getParams() {
  await supabase
    /*.rpc("getmissionsbyteam", {
      var_team_id: props.teamId,
    })*/
    .from("team_mission")
    .select("resolved,unlocked_at,mission_id,solved_at,mission_id")
    .eq("team_id", props.teamId)
    .order("ord")
    .then((d) => {
      //debugger;
      missions.value = d.data;
    });
}

function unlockedMission() {
  ElNotification({
    title: "Mission bloquée",
    message:
      "Sérieux, t'as même pas réussi les missions d'avant et tu veux attaquer celle là",
    position: "top-right",
    type: "info",
  });
}
</script>

<style lang="scss" scoped>
.resolved {
  background-color: green;
}

.running {
  background-color: rgb(128, 115, 0);
}

.locked {
  background-color: rgb(128, 26, 0);
}

.active {
  border-bottom: 2px solid #ffe614;
}

.mission-style {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: aliceblue;
}

@media (max-width: 400px) {
  .mission-style {
    height: 28px;
    width: 28px;
  }
}

@media (min-width: 400px) {
  .mission-style {
    height: 35px;
    width: 35px;
  }
}
</style>
